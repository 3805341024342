import React from "react";
import AOS from 'aos';
import { Link } from 'react-router-dom';

AOS.init();
const ProjectsPage = () => {
    return (
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="projectsPage">
          {/* <div className="container container-fluid p-5">
            <h1 className="display-4 pb-5">Some of my projects...</h1>
          </div> */}
          <div className="container">
            <div className="tab-content gallery mt-5">
              <div className="tab-pane active">
                <div className="ml-auto mr-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <a role="button" href="https://github.com/menachemtsadok/wheeloffortune" target="_blank">  
                          <figure className="cc-effect"><img src={require('./Images/wheel-of-fortune.png')} alt=""/>
                            <figcaption>
                              <div className="h4">Wheel of Fortune</div>
                              <p>Wheel of fortune game that was created for a family game night.</p>
                            </figcaption>
                          </figure>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <a role="button" href="https://github.com/menachemtsadok/sudoku-solver" target="_blank">  
                          <figure className="cc-effect"><img src={require('./Images/sudoku.png')} alt=""/>
                            <figcaption>
                              <div className="h4">Sudoku Solver</div>
                              <p>A program that efficiently solves any valid sudoku puzzle.</p>
                            </figcaption>
                          </figure>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <figure className="cc-effect"><img src={require('./Images/raspberry-pi.jpg')} alt=""/>
                          <figcaption>
                            <div className="h4">Raspberry Pi Server</div>
                            <p>A Raspberry Pi device turned into a server running Raspbian. It was made to be used as an extra storage from anywhere.</p>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <figure className="cc-effect"><img src={require('./Images/chatbot.jpg')} alt=""/>
                          <figcaption>
                            <div className="h4">Atom Chatbot</div>
                            <p>A Chatbot created for the CUNY NYCCT that was used for new students to ask general questions. Such as: where is the Bursar?, What room is Professor Pinto's office?, What are Professor Marcus's office hours?</p>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default ProjectsPage;
