import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';
import './aboutStyleCSS.css';
import './bootstrap.min.css';

AOS.init();

const Experience = () => {
  return (
    <div>
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0" id="experience">
          <div className="container cc-experience">
            <h5 className="display-4 mb-5 text-center">Work Experience</h5>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/NPS.jpg')} width="50%" height="90%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>July 2019 - Present</p>
                      <div className="h5">National Public Seating</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Software Developer</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Implemented Java techniques to create in-house software.</li>
                      <li>Learned and processed the AS400 system to create programs in it without supervision from senior managers.</li>
                      <li>Created programs for the IBM AS400 system written in RPG IV and Control Language to retrieve reports based on parameters.</li>
                      <li>Developed SQL queries to obtain custom reports and to modify the database.</li>
                      <li>Set up scheduled processes and database triggers to send emails and reports.</li>
                      <li>Created a program to automatically create forms on Prontoforms using Python and Selenium to increase productivity.</li>
                      <li>Collaborated with product and purchasing team to create software to automate and ease the workflow and increase efficiency.</li>
                      <li>Manage and maintain all software created and ensure proper documentation is made.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  {/* <img src={require('./Images/CE.png')} width="50%" height="100%" className="card-img" alt="..."/> */}
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>September 2017 - Present</p>
                      <div className="h5">Tutor, Self-employed</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Tutor, Self-employed</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Tutor in: Java, Pre-Calculus, Calculus 1, and Calculus 2.</li>
                      <li>Comprehensive review of class exams to assess mistakes and weakness.</li>
                      <li>Create a lesson plan to address student weaknesses.</li>
                      <li>Patiently assist students in preparing for class exams.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/MHC.jpg')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>June 2018 - December 2018</p>
                      <div className="h5">Magic Home Care</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">Data Entry</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Created a program to modify excel sheets using Python and Pandas to increase accuracy and reduce human error.</li>
                      <li>Created professional digital library of files containing personal time sheets and employee applications using Adobe Acrobat .</li>
                      <li>Routinely troubleshoot and resolve technical issues.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/Jroot.png')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>Summer 2016</p>
                      <div className="h5">JRoot Radio Station</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">Software Developer Intern</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Configured Raspberry Pis.</li>
                      <li>Built a Java application to easily edit Raspberry Pi contents.</li>
                      <li>Set up and organized efficient workstations.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Experience;
