import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';

AOS.init();

const Education = () => {
    return (
      <div>
        <div className="page-content" id="education"> 
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container cc-education">
              <h5 className="display-4 mb-5 text-center">Education</h5>
              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/BC.jpg')} width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2015 - 2019</p>
                        <div className="h5">B.S. - Computer Science</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">CUNY Brooklyn College</div>
                      <p className="category">3.75 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Engineering</li>
                        <li>Honors: Academic Excellence Dept. Of Computer Science, Magna Cum Laude, Dean's List 2015 - 2019</li>
                        <li>
                          Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Computer Science: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Data Structures, Software Design and Implementation 1-2, Computer Architecture, Robotics, Operating Systems, 
                                  Object Oriented Programming, Database Systems, Theoretical Computer Science
                                </li>
                              </ul>
                            </li>
                            <li>Mathematics: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Calculus 1, Calculus 2, Calculus 3, Probability and Statistics, Discrete Mathematics</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/yuhsb.png')} style={{paddingTop: "70px"}} className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2011 - 2015</p>
                        <div className="h5">Yeshiva University High School for Boys</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">Yeshiva University High School for Boys</div>
                      <p className="category">3.81 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Engineering</li>
                        <li>Honors: School Service Award, Advanced Regents Diploma</li>
                        <li>Standardized Tests: SAT</li>
                        <li>Regents: 
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Algebra, Geometry, Trigonometry, Earth Science, Biology, Global History, US History, Hebrew, English</li>
                          </ul>
                        </li>
                        <li>Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Introductory Coding Class</li>
                            <li>Math/Science: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Algebra, Geometry, Trigonometry, Pre-Calculus</li>
                                <li>Earth Science, Biology, Chemistry, Physics</li>
                              </ul>
                            </li>
                            <li>History/Languages: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Global History, U.S. History</li>
                                <li>English, Hebrew</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
export default Education;